<template>
  <div>
    <div class="iss-main">
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <div style="display: flex">
          <div style="width: 600px">
            <a-form-item label="语言" v-bind="validateInfos.language">
              <a-radio-group
                v-model:value="form.language"
                :options="languageList"
              />
            </a-form-item>
            <a-form-item label="新闻类型" v-bind="validateInfos.newType">
              <a-select v-model:value="form.newType" placeholder="请选择">
                <a-select-option
                  v-for="item in newsList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.categoryName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="发布时间" v-bind="validateInfos.releaseTime">
              <!-- format="YYYY-MM-DD" -->
              <a-date-picker
                v-model:value="form.releaseTime"
                format="YYYY-MM-DD"
                show-time
                type="date"
                placeholder="发布时间"
                style="width: 100%"
                :disabled="form.disabled"
              />
            </a-form-item>

            <a-form-item label="banner图" v-bind="validateInfos.bannerUrl">
              <iss-image-upload
                v-model:value="form.bannerUrl"
                list-type="picture-card"
                accept=".jpe,.jpeg,.jpg,.png"
              />
            </a-form-item>
            <a-form-item label="新闻标题" v-bind="validateInfos.newsTitle">
              <a-input
                v-model:value="form.newsTitle"
                placeholder="请输入新闻标题"
              />
            </a-form-item>

            <a-form-item label="新闻简介" v-bind="validateInfos.newsLetter">
              <a-textarea
                v-model:value="form.newsLetter"
                auto-size
                :maxlength="300"
                placeholder="请输入新闻简介"
              />
            </a-form-item>
            <a-form-item label="新闻来源" v-bind="validateInfos.newsSource">
              <a-input
                v-model:value="form.newsSource"
                placeholder="请输入新闻来源"
              />
            </a-form-item>
            <a-form-item label="跳转链接" v-bind="validateInfos.jumpUrl">
              <a-textarea
                v-model:value="form.jumpUrl"
                auto-size
                placeholder="请输入跳转链接"
              />
            </a-form-item>
            <a-form-item label="排序" v-bind="validateInfos.sort">
              <a-input-number v-model:value="form.sort" :min="1" :max="99" />
            </a-form-item>
          </div>
          <div style="flex: 1">
            <a-form-item
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
              v-bind="validateInfos.newsContent"
            >
              <div>内容</div>
              <TinyEditor
                style="height: calc(100vh - 218px)"
                ref="editorRef"
                v-model:value="form.newsContent"
              ></TinyEditor>
            </a-form-item>
          </div>
        </div>
      </a-form>
    </div>

    <div class="iss-but">
      <a-space>
        <a-button type="primary" @click="handleSave"> 保存 </a-button>
        <a-button @click="$router.go(-1)"> 返回 </a-button>
      </a-space>
    </div>
  </div>
</template>
<script>
import IssImageUpload from '@/components/imageUpload';
import TinyEditor from '@/components/TinyEditor';
import { reactive, toRefs, watch, ref, onBeforeUnmount, toRaw } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  Form,
  Radio,
  Select,
  DatePicker,
  message,
  InputNumber,
  Space,
} from 'ant-design-vue';
import newsApi from '@/api/news.js';
import moment from 'moment';
import classifyApi from '@/api/classify';
// import formApi from '@/api/form';
export default {
  components: {
    TinyEditor,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARadioGroup: Radio.Group,
    ADatePicker: DatePicker,
    AInputNumber: InputNumber,
    ASpace: Space,
    IssImageUpload,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    initValue: {
      type: Object,
      default: () => ({}),
    },
    container: { type: Object },
  },
  setup(props) {
    const editorRef = ref();
    const modalRef = ref();
    const route = useRoute();
    const router = useRouter();
    const { id } = route.params;
    const required = { required: true, message: '不能为空' };
    const form = reactive({
      id: '',
      newType: '',
      releaseTime: '',
      bannerUrl: '',
      newsTitle: '',
      newsLetter: '',
      newsContent: '',
      jumpUrl: '',
      newsSource: '',
      language: '',
      sort: '',
    });
    const state = reactive({
      newsList: [],
      languageList: [
        { label: '中文', value: 'Chinese' },
        { label: '英文', value: 'English' },
      ],
    });
    const { validateInfos, validate, resetFields } = Form.useForm(
      form,
      reactive({
        // guestName: [required, { max: 30, message: '嘉宾姓名不能超过30个字符' }],
        releaseTime: [required],
        language: [required],
        newType: [required],
        bannerUrl: [required, { type: 'url', message: '请输入正确的链接格式' }],
        newsTitle: [required],
        newsLetter: [required],
        newsContent: [],
        jumpUrl: [required, { type: 'url', message: '请输入正确的链接格式' }],
      })
    );
    watch(
      () => props.initValue,
      initValue => {
        if (initValue.id) {
          Object.assign(form, initValue, {
            date: [moment(initValue.startTime), moment(initValue.endTime)],
          });
        } else {
          resetFields();
        }
      }
    );

    newsApi.getNews('', id).then(data => {
      Object.assign(form, data);
    });

    // 查询新闻类型下拉
    classifyApi.getCategoryTree('', { moduleCode: 'news' }).then(data => {
      state.newsList = data;
    });

    onBeforeUnmount(() => {
      editorRef.value && editorRef?.value.remove();
    });

    return {
      ...toRefs(state),
      form,
      modalRef,
      validateInfos,
      handleSave: () => {
        console.log('form', form.newsTitle);

        form.releaseTime = moment(form.releaseTime)
          .format('YYYY-MM-DD')
          .toString();
        validate().then(() => {
          let type = 'addNews';
          const { id } = form;
          id && (type = 'updateNews');

          newsApi[type](`news:${type}`, toRaw(form)).then(() => {
            message.success('操作成功');
            router.go(-1);
          });
        });
      },
      handleAfterClose: () => {
        editorRef.value && editorRef?.value.remove();
      },
    };
  },
};
</script>
<style scoped>
/* .model {
  max-height: 600px;
} */
.iss-main {
  height: calc(100vh - 132px);
  overflow-y: auto;
}
.iss-but {
  float: right;
  margin-right: 16px;
}

/*.center-box {*/
/*  max-height: calc(100vh - 160px);*/
/*  overflow-y: auto;*/
/*  border-radius: 8px;*/
/*}*/
</style>
